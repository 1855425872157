// extracted by mini-css-extract-plugin
export var Stories = "Stories-module--Stories--Jlhf5";
export var Stories_container = "Stories-module--Stories_container--9FqxE";
export var Stories_item = "Stories-module--Stories_item--1E633";
export var Stories_item_content = "Stories-module--Stories_item_content--y3z7t";
export var Stories_item_image = "Stories-module--Stories_item_image--Ird5y";
export var Stories_item_info = "Stories-module--Stories_item_info--ED-6i";
export var Stories_item_logo = "Stories-module--Stories_item_logo--DWfPK";
export var Stories_item_meta = "Stories-module--Stories_item_meta--ySoPH";
export var container = "Stories-module--container--BZs6i";
export var gatsbyImageWrapperConstrained = "Stories-module--gatsby-image-wrapper-constrained--0VAXd";
export var gridSpan1010 = "Stories-module--grid-span-10-10--WBTQE";
export var gridSpan1011 = "Stories-module--grid-span-10-11--IK6oq";
export var gridSpan102 = "Stories-module--grid-span-10-2--wlLc7";
export var gridSpan103 = "Stories-module--grid-span-10-3--LF-ZY";
export var gridSpan104 = "Stories-module--grid-span-10-4--zuev6";
export var gridSpan105 = "Stories-module--grid-span-10-5--5YjNh";
export var gridSpan106 = "Stories-module--grid-span-10-6--qosaR";
export var gridSpan107 = "Stories-module--grid-span-10-7--kCWCO";
export var gridSpan108 = "Stories-module--grid-span-10-8--yRcHq";
export var gridSpan109 = "Stories-module--grid-span-10-9--aYR46";
export var gridSpan110 = "Stories-module--grid-span-1-10--eYKH+";
export var gridSpan111 = "Stories-module--grid-span-1-11--47Kgw";
export var gridSpan1110 = "Stories-module--grid-span-11-10--E8deg";
export var gridSpan1111 = "Stories-module--grid-span-11-11--5QPtv";
export var gridSpan112 = "Stories-module--grid-span-11-2--WxeZm";
export var gridSpan113 = "Stories-module--grid-span-11-3--869ab";
export var gridSpan114 = "Stories-module--grid-span-11-4--4lWYA";
export var gridSpan115 = "Stories-module--grid-span-11-5--U4gWm";
export var gridSpan116 = "Stories-module--grid-span-11-6--XU0SH";
export var gridSpan117 = "Stories-module--grid-span-11-7--Wsdmm";
export var gridSpan118 = "Stories-module--grid-span-11-8--NjAC5";
export var gridSpan119 = "Stories-module--grid-span-11-9--OXhLz";
export var gridSpan12 = "Stories-module--grid-span-1-2--p2Jfp";
export var gridSpan1210 = "Stories-module--grid-span-12-10--XIWB8";
export var gridSpan1211 = "Stories-module--grid-span-12-11--C3caR";
export var gridSpan122 = "Stories-module--grid-span-12-2--whj0c";
export var gridSpan123 = "Stories-module--grid-span-12-3--whjQZ";
export var gridSpan124 = "Stories-module--grid-span-12-4--AJUWf";
export var gridSpan125 = "Stories-module--grid-span-12-5--M+OX2";
export var gridSpan126 = "Stories-module--grid-span-12-6--H9iqP";
export var gridSpan127 = "Stories-module--grid-span-12-7--A+rF6";
export var gridSpan128 = "Stories-module--grid-span-12-8--DxFej";
export var gridSpan129 = "Stories-module--grid-span-12-9--4DIAM";
export var gridSpan13 = "Stories-module--grid-span-1-3--XY5mt";
export var gridSpan14 = "Stories-module--grid-span-1-4--T+n3G";
export var gridSpan15 = "Stories-module--grid-span-1-5--xMz4x";
export var gridSpan16 = "Stories-module--grid-span-1-6--V6kg9";
export var gridSpan17 = "Stories-module--grid-span-1-7--gGEXr";
export var gridSpan18 = "Stories-module--grid-span-1-8--JEYXL";
export var gridSpan19 = "Stories-module--grid-span-1-9--R2L8F";
export var gridSpan210 = "Stories-module--grid-span-2-10--OROED";
export var gridSpan211 = "Stories-module--grid-span-2-11--1BpVb";
export var gridSpan22 = "Stories-module--grid-span-2-2--WgBfc";
export var gridSpan23 = "Stories-module--grid-span-2-3--1APBn";
export var gridSpan24 = "Stories-module--grid-span-2-4--ptkWn";
export var gridSpan25 = "Stories-module--grid-span-2-5--dIwDJ";
export var gridSpan26 = "Stories-module--grid-span-2-6--N5cjf";
export var gridSpan27 = "Stories-module--grid-span-2-7--o19oE";
export var gridSpan28 = "Stories-module--grid-span-2-8--JG5-c";
export var gridSpan29 = "Stories-module--grid-span-2-9--L-28A";
export var gridSpan310 = "Stories-module--grid-span-3-10--NsDxX";
export var gridSpan311 = "Stories-module--grid-span-3-11--ijAZX";
export var gridSpan32 = "Stories-module--grid-span-3-2--bZNfw";
export var gridSpan33 = "Stories-module--grid-span-3-3--ZXEtF";
export var gridSpan34 = "Stories-module--grid-span-3-4--b3ZZc";
export var gridSpan35 = "Stories-module--grid-span-3-5--bIR6a";
export var gridSpan36 = "Stories-module--grid-span-3-6--tYwSR";
export var gridSpan37 = "Stories-module--grid-span-3-7--1Rb3r";
export var gridSpan38 = "Stories-module--grid-span-3-8--hH4Yy";
export var gridSpan39 = "Stories-module--grid-span-3-9--j7hV6";
export var gridSpan410 = "Stories-module--grid-span-4-10--XTmze";
export var gridSpan411 = "Stories-module--grid-span-4-11--DAo82";
export var gridSpan42 = "Stories-module--grid-span-4-2--kXDOd";
export var gridSpan43 = "Stories-module--grid-span-4-3--oj79E";
export var gridSpan44 = "Stories-module--grid-span-4-4--W2iAt";
export var gridSpan45 = "Stories-module--grid-span-4-5--lSam8";
export var gridSpan46 = "Stories-module--grid-span-4-6--wh90w";
export var gridSpan47 = "Stories-module--grid-span-4-7--Lj-0K";
export var gridSpan48 = "Stories-module--grid-span-4-8--SiU-B";
export var gridSpan49 = "Stories-module--grid-span-4-9--A4sTc";
export var gridSpan510 = "Stories-module--grid-span-5-10--O1Lq3";
export var gridSpan511 = "Stories-module--grid-span-5-11--SKxDf";
export var gridSpan52 = "Stories-module--grid-span-5-2--AziXI";
export var gridSpan53 = "Stories-module--grid-span-5-3--P3yNy";
export var gridSpan54 = "Stories-module--grid-span-5-4--ZEcDI";
export var gridSpan55 = "Stories-module--grid-span-5-5--xhFgJ";
export var gridSpan56 = "Stories-module--grid-span-5-6--vG-a5";
export var gridSpan57 = "Stories-module--grid-span-5-7--ew9FQ";
export var gridSpan58 = "Stories-module--grid-span-5-8--sYPKD";
export var gridSpan59 = "Stories-module--grid-span-5-9--F8zTZ";
export var gridSpan610 = "Stories-module--grid-span-6-10--CAj-W";
export var gridSpan611 = "Stories-module--grid-span-6-11--4EzYM";
export var gridSpan62 = "Stories-module--grid-span-6-2--v3uI+";
export var gridSpan63 = "Stories-module--grid-span-6-3--M25KR";
export var gridSpan64 = "Stories-module--grid-span-6-4--CtXHP";
export var gridSpan65 = "Stories-module--grid-span-6-5--JXWrk";
export var gridSpan66 = "Stories-module--grid-span-6-6--P9e92";
export var gridSpan67 = "Stories-module--grid-span-6-7--YmQ54";
export var gridSpan68 = "Stories-module--grid-span-6-8--G2TOh";
export var gridSpan69 = "Stories-module--grid-span-6-9--4c2fT";
export var gridSpan710 = "Stories-module--grid-span-7-10--YFm3E";
export var gridSpan711 = "Stories-module--grid-span-7-11--nPctV";
export var gridSpan72 = "Stories-module--grid-span-7-2--9-qyK";
export var gridSpan73 = "Stories-module--grid-span-7-3--bGlyY";
export var gridSpan74 = "Stories-module--grid-span-7-4--7PZwz";
export var gridSpan75 = "Stories-module--grid-span-7-5--vKcMJ";
export var gridSpan76 = "Stories-module--grid-span-7-6--zsh13";
export var gridSpan77 = "Stories-module--grid-span-7-7--J5+kr";
export var gridSpan78 = "Stories-module--grid-span-7-8--ior3x";
export var gridSpan79 = "Stories-module--grid-span-7-9--FasLC";
export var gridSpan810 = "Stories-module--grid-span-8-10--IIfYY";
export var gridSpan811 = "Stories-module--grid-span-8-11--Q9ABu";
export var gridSpan82 = "Stories-module--grid-span-8-2--+PIfq";
export var gridSpan83 = "Stories-module--grid-span-8-3--dP8QF";
export var gridSpan84 = "Stories-module--grid-span-8-4--kenyn";
export var gridSpan85 = "Stories-module--grid-span-8-5---hUxE";
export var gridSpan86 = "Stories-module--grid-span-8-6--4Yz+F";
export var gridSpan87 = "Stories-module--grid-span-8-7--UK6wY";
export var gridSpan88 = "Stories-module--grid-span-8-8--WFCAP";
export var gridSpan89 = "Stories-module--grid-span-8-9--I9OBV";
export var gridSpan910 = "Stories-module--grid-span-9-10--QMrvO";
export var gridSpan911 = "Stories-module--grid-span-9-11--lVNaG";
export var gridSpan92 = "Stories-module--grid-span-9-2--oaxZv";
export var gridSpan93 = "Stories-module--grid-span-9-3--UPGkX";
export var gridSpan94 = "Stories-module--grid-span-9-4--n1vgt";
export var gridSpan95 = "Stories-module--grid-span-9-5--wlwUC";
export var gridSpan96 = "Stories-module--grid-span-9-6--UrNYo";
export var gridSpan97 = "Stories-module--grid-span-9-7--sRgzA";
export var gridSpan98 = "Stories-module--grid-span-9-8--xabyj";
export var gridSpan99 = "Stories-module--grid-span-9-9--YUZJp";
export var textBreak = "Stories-module--text-break--XaZPg";