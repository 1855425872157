import React from "react";
import cx from "classnames";

import * as styles from "./Features.module.scss";

const Features: React.FC = () => {
  return (
    <section className={styles.Features}>
      <div className={cx("container", styles.Features_content)}>
        <header className={styles.Features_header}>
          <h2 className={styles.Features_header_title}>
            The growth partner you need for your business
          </h2>
        </header>

        <div className={styles.Features_list}>
          <div className={styles.Features_item}>
            <h4 className={styles.Features_item_title}>
              Flexible repayment plans
            </h4>
            <p className={styles.Features_item_body}>
              Repay at your own convenience. Choose either the daily, weekly or
              monthly repayment plan.
            </p>
          </div>
          <div className={styles.Features_item}>
            <h4 className={styles.Features_item_title}>
              Collateral free credit
            </h4>
            <p className={styles.Features_item_body}>
              Say goodbye to long requirement forms. Get the credit you need on
              time and with no collateral.
            </p>
          </div>
          <div className={styles.Features_item}>
            <h4 className={styles.Features_item_title}>
              Receive financing fast
            </h4>
            <p className={styles.Features_item_body}>
              Once you are eligible for capital, get the cashflow you need in
              your account whenever you want.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
