import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Button, ButtonVariant } from "brass-ui-kit";

import * as styles from "./Hero.module.scss";

import { APP_SIGNUP_URL } from "data/config";

const Hero: React.FC = () => {
  return (
    <section className={styles.Hero}>
      <header className={cx(styles.Hero_header)}>
        <p className={styles.Hero_header_badge}>
          Capital is available based on your cashflow performance and history
          with us.
        </p>
        <div className={styles.Hero_header_main}>
          <h1 className={styles.Hero_header_text}>
            Never run out of cash for your business
          </h1>
          <p className={styles.Hero_header_body}>
            Growth usually means more capital to finance your invoices, support
            your cashflow. Let's help you. Get started in minutes.
          </p>
          <Button
            style={{ width: "fit-content", margin: "auto" }}
            as={"a"}
            herf={APP_SIGNUP_URL}
            target="_blank"
            variant={ButtonVariant.Primary}
          >
            Get started in minutes
          </Button>
        </div>
      </header>
      <div className={styles.Hero_backgroundImage}>
        <StaticImage
          as="span"
          alt="hero"
          loading="eager"
          src="../../../assets/media/images/capital-hero.png"
        />
      </div>
    </section>
  );
};

export default Hero;
