import * as styles from "./Stories.module.scss";

import { CAPITAL_DATA } from "data/pages";
import React from "react";

const Stories: React.FC = () => {
  return (
    <section className={styles.Stories}>
      <div className={styles.Stories_container}>
        {CAPITAL_DATA.TESTIMONIALS.map(
          ({ story, name, image, profile, title }, index) => (
            <div key={index} className={styles.Stories_item}>
              <div>
                <div className={styles.Stories_item_image}>
                  <img src={profile} alt="" />
                </div>

                <p className={styles.Stories_item_content}>{story}</p>
              </div>
              <div className={styles.Stories_item_meta}>
                <div className={styles.Stories_item_info}>
                  <h4>{name}</h4>
                  <p>{title}</p>
                </div>
                <div className={styles.Stories_item_logo}>{image}</div>
              </div>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default Stories;
