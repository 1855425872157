import React from "react";
import { graphql, PageProps } from "gatsby";

import { CAPITAL_DATA, HOME_DATA } from "data/pages";

import {
  Support,
  Resources,
  FaqWidget,
  GetStarted,
  QualitiesList,
} from "components/design-system";
import SEO from "components/SEO";

import { Hero, Features, Stories } from "components/capital";
import pagesMeta from "data/pagesMeta";

const Capital: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;
  return (
    <>
      <SEO {...pagesMeta.capital} />
      <Hero />
      <Support
        theme="dark"
        reverse={true}
        hasPadding={false}
        items={pageData.supportItems}
      />

      <Features />

      <Stories />

      <FaqWidget content={pageData.faqContent} />

      <Resources
        align="left"
        titleAlign="left"
        items={pageData.resources}
        title="Resources to learn more about our capital"
        description={
          "Curated articles and resources to help you get started on eligibility, and repayment of our capital."
        }
      />

      <GetStarted
        theme="light"
        children={
          <QualitiesList items={CAPITAL_DATA.QUALITIES} isEmbed={true} />
        }
      />
    </>
  );
};

export const query = graphql`
  query CapitalPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "capital" } } }
    ) {
      edges {
        node {
          frontmatter {
            supportItems {
              description
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }

            resources {
              link
              title
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }

            faqContent {
              answer
              question
            }
          }
        }
      }
    }
  }
`;

export default Capital;
